import React from 'react';

export default function Logo({ fill }) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="75" height="80" viewBox="0 0 500.000000 500.000000"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#03DAC5" stroke="none">
<path d="M1903 3529 l-593 -341 0 -688 0 -688 583 -336 c321 -184 591 -338
600 -341 10 -5 219 110 612 336 l595 342 0 687 0 687 -594 341 c-327 188 -598
342 -602 342 -5 0 -276 -154 -601 -341z m518 -824 c41 -107 77 -195 80 -195 3
0 39 88 80 195 l74 195 88 0 c66 0 87 -3 87 -13 0 -8 14 -164 30 -347 17 -183
30 -335 30 -337 0 -2 -36 -3 -80 -3 -69 0 -80 2 -80 18 0 9 -7 94 -15 187 -8
94 -15 179 -15 190 0 38 -18 -3 -80 -185 -35 -102 -68 -191 -73 -197 -5 -7
-27 -13 -49 -13 -46 0 -37 -16 -123 230 -32 91 -61 169 -65 173 -4 4 -12 -66
-19 -155 -7 -90 -15 -182 -18 -205 l-5 -43 -80 0 -80 0 5 33 c3 17 18 175 34
350 l27 317 86 0 86 0 75 -195z"/>
</g>
</svg>
  );
}

// import logo from "../../../../assets/mohan.png" // Tell webpack this JS file uses this image
// console.log(logo) // /logo.84287d09.png
// function Header() {
//   // Import result is the URL of your image
//   return <img src={logo} alt="Logo" />
// }
// export default Header